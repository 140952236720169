/**
 * This configuration is used to make staging area easier for testing.
 * This is simple local backend express/test server that will mock some responses
 * from ProgressApi and ProjectApi while proxying others.
 *
 * To use this configuration, you need to run this locally:
 * - https://dev.azure.com/faro-connect/Stellar/_git/dashboard-mock-test-server
 * - https://faro01.atlassian.net/wiki/spaces/FC/pages/4465721786/Staging+Area+-+Testing+server
 */

import { RuntimeConfig } from "@src/runtime-config-types";
import { runtimeConfig as runtimeConfigComDev } from "@src/runtime-config-com-dev";

export const runtimeConfig: RuntimeConfig = {
  ...runtimeConfigComDev,
  appEnv: "com-dev-with-mocks",
  urls: {
    ...runtimeConfigComDev.urls,
    progressApiUrl: "http://localhost:4056/progressapi",
    projectApiUrl: "http://localhost:4056",
  },
};
